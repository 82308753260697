import React from 'react'
import { GlobalWrapper } from './src/components/global-wrapper'

export const wrapPageElement = ({ element, props }) => {
  return (
    <GlobalWrapper {...props}>
      {element}
    </GlobalWrapper>
  )
}

